<template>
    <div class="middle-box text-center loginscreen animated fadeInDown">

        <div>
            <div class="form">
                <form @submit.prevent="forgotPassword">
                    <div class="form-group">
                        <label for="identifiant" class="form-label">E-mail</label>
                        <input v-model="email" type="text" class="form-control" id="email" placeholder="Votre e-mail">
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn-submit">Envoyer</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import { AUTH_FORGOT_PASSWORD } from "../store/actions/auth";

export default {
  name: "login",
  data() {
    return {
      email: ""
    };
  },
  components: {
  },
  methods: {
    forgotPassword: function() {
      const { email } = this;
      this.$store.dispatch(AUTH_FORGOT_PASSWORD, { email }).then(() => {
        this.$router.push("/");
      });
    }
  }
};
</script>

<style scoped lang="scss">
    ion-header,
    ion-content {
        --background: var(--lightpink) !important;
        background: var(--lightpink) !important;
    }

    ion-header {
        padding: 2.5rem 0 3.5rem;
        text-align: center;

        h1 {
            display: block;
            font-family: var(--font-cond);
            font-weight: 600;
            font-size: 1.3rem;
            text-transform: uppercase;

            img {
                display: block;
                margin: 0 auto 1.5rem;
            }
        }
    }

    .btn-close {
        position: fixed;
        top: 2rem;
        right: 1rem;
    }

    .forgot-pwd {
        font-size: 1.4rem;
        text-align: right;
    }

    .bloc-inscription {
        background: var(--ion-background-color) url("/assets/img/separ.svg") no-repeat 0 0;
        background-size: 100%;
        color: #303030;
        font-size: 1.6rem;
        padding: 2rem 35% 2rem 3rem;
        position: relative;

        img {
            position: absolute;
            right: -5rem;
            top: 0;
        }

        strong {
            font-size: 1.8rem;
            font-weight: 900;
        }
    }
</style>